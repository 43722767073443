@import "../general/import-general-only";
@import "common/mobile-newsletter";
@import "../variables/layout/footer";

@mixin footerDesign() {
	FOOTER {
		.footerContainer {
			background-color: $footer-background-color;
			border-top: 2px solid $footer-background-border-top-color;

			.mobilenewsletter {
				@include mobile_newsletter();
			}

			.whychoose {
				padding: 25px 0px 20px 0px;
				margin: 0 auto;
				color: $footer-whychoose-color;
				background-color: $footer-whychoose-background-color;

				@include media-breakpoint-down(md) {
					padding: 20px 15px;
				}

				.row {
					margin: 0;

					.tableContainer {
						display: table;
						width: 100%;
						padding-right: 0;
						padding-left: 0;
						table-layout: auto;

						@include media-breakpoint-down(md) {
							display: block;
						}

						.tableRow {
							display: flex;
							justify-content: space-between;

							@include media-breakpoint-down(md) {
								display: block;
							}

							.tableCell {
								font-size: 14px;

								@include sf_pro_textlight;

								@include media-breakpoint-down(md) {
									display: block;
									max-width: 100%;
									text-align: center;
								}

								&.title .box {
									padding-left: 0;
									font-size: 18px;
									color: $footer-whychoose-box-title-color;

									@include sf_pro_textregular;
								}

								.trustpilot-widget-box {
									@include media-breakpoint-down(lg) {
										left: -40px;
									}
								}

								&.more_link {
									.box {
										padding-right: 0;
										padding-left: 0;
										text-align: right;

										@include media-breakpoint-down(md) {
											text-align: left;
										}
									}
								}

								.box {
									position: relative;
									padding-right: 50px;
									color: $footer-whychoose-box-color;

									&.box-lastItem {
										padding-right: 0;
									}

									@include media-breakpoint-down(md) {
										position: relative;
										padding: 4px 0 4px 30px;
										margin: 0;
										font-size: 14px;
										text-align: left;

										&.box-lastItem {
											padding-right: 0;
										}
									}

									I {
										position: absolute;
										top: 5px;
										width: 21px;
										height: 21px;
										margin-top: 0;
										margin-left: -30px;
										font-size: 10px;
										line-height: 21px;
										color: $white;
										text-align: center;
										background: $footer-whychoose-i-color;
										border-radius: 30px;

										&:before {
											position: relative;
											display: inline-block;
										}
									}

									A.link_under {
										font-size: 12px;
										color: $tertiary-blue;
										text-decoration: underline;

										@include sf_pro_textlight;

									}
								}
							}
						}
					}
				}

				.whychoose-link-container {
					margin: 10px 0 0 0;
					text-align: right;

					@include media-breakpoint-down(md) {
						text-align: left;
					}

					A {
						font-size: 14px;
						color: $footer-whychoose-type2-link-color;
						text-decoration: underline;
					}
				}
			}

			.aboutus {
				color: $footer-aboutus-text-color;
				background-color: $footer-aboutus-background-color;

				@include media-breakpoint-down(md) {
					color: $white;
					background-color: $footer-aboutus-mobile-background-color;
				}

				.subContainer {
					padding: 20px 0 20px 0;
					margin: 0 auto;

					@include media-breakpoint-down(lg) {
						padding: 40px 15px;
					}

					.row {
						margin: 0 -15px;

						.panel {
							.panel-heading {
								pointer-events: none;

								@include media-breakpoint-down(md) {
									pointer-events: auto;
								}

								&.collapsed {
									.panel-title {
										&:after {
											@include media-breakpoint-down(md) {
												transform: translate(0, 10px);

												@include css-triangle(5px, $white, top)
											}
										}
									}
								}

								.panel-title {
									@include sf_pro_textregular;
									font-size: 18px;
									color: $footer-aboutus-panel-header-color;

									@include media-breakpoint-down(md) {
										padding-bottom: 5px;
										color: $white;
										cursor: pointer;
										border-bottom: 1px solid rgba(255, 255, 255, 0.3);

										&:after {
											display: inline-block;
											float: right;
											width: 20px;
											height: 20px;
											line-height: 20px;
											color: $white;
											vertical-align: top;
											transform: translate(0, 5px);

											@include sf_pro_textregular;
											@include css-triangle(5px, $white, bottom)
										}
									}
								}
							}

							@include media-breakpoint-up(md) {
								.panel-collapse {
									&.collapse:not(.show) {
										display: block;
									}
								}
							}

							@include media-breakpoint-down(md) {
								display: block;
								flex-wrap: unset;
								margin: 0;

								.panel {
									max-width: none;
									padding: 0;
									margin: 0;
									border-bottom: 2px solid rgba(255, 255, 255, 0.2);

									.panel-heading {
										position: relative;
										padding: 14px 0;
										cursor: pointer;

										.panel-title {
											margin: 0;
										}
									}

									.panel-heading[data-bs-toggle="collapse"]:after {
										position: absolute;
										top: calc(50% - 10px);
										right: 20px;
										font-family: 'eventTripsIcon';
										font-size: 8px;
										line-height: 22px;
										color: $white;
										content: '\e937';
										transform: rotate(0deg);
									}

									.panel-heading[data-bs-toggle="collapse"].collapsed:after {
										transform: rotate(180deg);
									}

									.panel-collapse {
										padding-bottom: 15px;
									}
								}
							}
						}

						UL {
							padding-left: 0;
							margin: 0;

							@include media-breakpoint-down(md) {
								text-align: center;
							}

							LI {
								line-height: 30px;
								color: $footer-aboutus-title-color;
								list-style: none;

								&.title {
									@include media-breakpoint-down(md) {
										display: block;
									}
									font-size: 18px;
									line-height: 30px;

									@include sf_pro_textsemibold;
								}

								&.item {
									@include media-breakpoint-down(md) {
										display: inline-block;
										margin: 0 3px;
									}

									@include media-breakpoint-down(lg) {
										display: block;
										padding: 0;
										margin: 0;
										text-align: left;
									}

									A {
										font-size: 14px;
										line-height: 25px;
										color: $footer-aboutus-item-color;
										text-decoration: none;

										@include sf_pro_textlight;

										@include media-breakpoint-down(md) {
											color: $footer-aboutus-item-color-mobile;
										}

										&:hover {
											text-decoration: underline;
										}
									}
								}
							}

							&.social {
								li.item {
									display: inline-block;
									width: 50px;
									height: 50px;
									margin-right: 10px;
									text-align: center;
									background-color: transparent;
									border: 2px solid $footer-aboutus-social-border-color;
									border-radius: 25px;
									transition: background-color 0.3s ease 0s;

									@include media-breakpoint-down(md) {
										border: 2px solid $footer-aboutus-social-border-mobile-color;
									}

									a {
										font-size: 30px;
										line-height: 50px;
										color: $footer-aboutus-social-link-color;
										text-decoration: none;

										@include media-breakpoint-down(md) {
											color: $footer-aboutus-social-link-mobile-color;
										}
									}

									&:hover {
										cursor: pointer;
										background-color: $footer-aboutus-social-link-color;
										transition: background-color 0.3s ease 0s;

										@include media-breakpoint-down(md) {
											background-color: $footer-aboutus-social-link-mobile-color;
										}

										a {
											color: $footer-aboutus-social-link-hover-color;

											@include media-breakpoint-down(md) {
												color: $footer-aboutus-social-link-hover-mobile-color;
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.questions {
				margin: 0;
				color: $footer-questions-color;
				background-color: $footer-questions-redesigned-background-color;

				@include media-breakpoint-down(md) {
					color: $footer-questions-mobile-color;
					background-color: $footer-questions-redesigned-background-color-small;
					border-top: 1px solid $gray-secondary;
				}

				.subContainer {
					padding: 35px 0px;
					margin: 0 auto;

					@include media-breakpoint-down(md) {
						padding: 30px 15px;
					}

					.row {
						margin: 0 -15px;

						@include media-breakpoint-down(lg) {
							margin: 0;
						}

						@include media-breakpoint-down(md) {
							> DIV[class*="col"] {
								flex: 0 0 100%;
								max-width: 100%;
								padding: 10px 0;
							}
						}

						.questions-subTitle {
							@include media-breakpoint-down(md) {
								text-align: left;
							}
							margin: 0 0 10px 0;
							font-size: 20px;
							line-height: 26px;
							vertical-align: middle;

							@include sf_pro_textregular;
						}

						P {
							font-size: 14px;
							line-height: 20px;

							@include sf_pro_textlight;

							@include media-breakpoint-down(md) {
								text-align: left;
							}

							&.garantee-wrapper {
								BR {
									display: none;
								}

								BR + A {
									display: inline-block;
									padding-left: 5px;
								}

								IMG.garantee {
									float: left;
									width: auto;
									max-width: none;
									height: 36px;
									margin: 0 10px 0 0;

									&[alt="SGR"] {
										background-color: $white;
									}
								}
							}

							img.garantee {
								margin: 10px 0;

								&[alt="SGR"] {
									background-color: $white;
								}
							}
						}

						a {
							color: $footer-questions-link-color;
							text-decoration: underline;
						}

						>.desktopNewsletter {
							@include media-breakpoint-up(xl) {
								padding: 0 30px;
							}
						}

						.desktopNewsletter {
							display: block;

							@include media-breakpoint-down(md) {
								display: none;
							}

							INPUT {
								display: inline-block;
								width: 64%;
								height: 40px;
								padding: 5px 15px;
								margin: 0 2% 0 0;
								font-size: 15px;
								color: $footer-questions-newsletter-input-color;
								background: $footer-questions-newsletter-input-background;
								border: 0;
								border-radius: 10px;

								&:first-letter {
									text-transform: uppercase;
								}
							}

							BUTTON {
								position: relative;
								top: 2px;
								display: inline-block;
								height: 44px;
								padding: 0 5px;
								margin: 5px 0 10px 0;
								font-size: 14px;
								color: $footer-questions-newsletter-button-type2-color;
								cursor: pointer;
								background-color: $footer-questions-newsletter-button-type2-border-color;
								border: 2px solid $footer-questions-newsletter-button-type2-border-color;
								border-radius: 10px;
								box-shadow: none;

								&:first-letter {
									text-transform: uppercase;
								}

								&:hover {
									color: $footer-questions-newsletter-button-type2-hover-color;
									background-color: $footer-questions-newsletter-button-type2-hover-background;
									transition: background-color 0.3s ease 0s;
								}
							}

							p.newsletterAfter {
								margin: 0;
							}

							.active-campaign-newsletter-holder {
								button._submit {
									margin-top: 0;
								}
							}
						}
					}
				}
			}

			.info {
				padding: 25px 0 0 0;
				margin: 0 auto;
				font-size: 10px;
				line-height: 2em;
				color: $footer-info-type2-color;
				text-align: center;
				background-color: $footer-info-type2-background-color;

				@include sf_pro_textlight;

				@include media-breakpoint-down(lg) {
					padding: 25px 15px 0 15px;
					font-size: 12px;
				}

				@include media-breakpoint-down(sm) {
					text-align: left;
				}

				a {
					color: $footer-info-type2-color;
					text-decoration: underline;

					&:hover {
						color: $footer-info-link-hover-color;
					}
				}
			}

			.logos {
				padding: 20px 0;
				text-align: center;
				background-color: $footer-logos-background-color;

				img {
					width: auto;
					height: 45px;
					margin: 0 10px;

					@include media-breakpoint-down(lg) {
						height: 30px;
						margin: 0;
					}
				}
			}

			.promotionBanner {
				position: fixed;
				bottom: 0;
				left: 0;
				z-index: 20;
				width: 100%;
				height: auto;
				padding: 0 5px;
				font-weight: 400;

				@include sf_pro_textregular;

				@include media-breakpoint-up(lg) {
					width: 50%;
				}

				@include media-breakpoint-up(xxl) {
					padding-left: 30px;
				}

				.accordion {
					@include media-breakpoint-up(xxl) {
						&.twist {
							position: relative;
							animation: twist .2s infinite;

							@keyframes twist {
								0%   {left: 0;}
								50%  {left: 7px;}
							}
						}
					}

					.card {
						background-color: $promotionBanner-backgroundColor;
						border-radius: 3px 3px 0 0;

						.head {
							color: $promotionBanner-head-color;
							cursor: pointer;

							.eventTripsIcon-material-shield {
								font-size: 22px;
							}

							h4 {
								position: relative;
								padding: 25px;
								margin: 0;
								font-size: 16px;

								@include sf_pro_textsemibold;

								@include media-breakpoint-up(lg) {
									padding: 15px;
									font-size: 18px;
								}
							}

							&[aria-expanded="false"] .eventTripsIcon-fas-chevron-down {
								transition: transform ease 0.3s;
								transform: rotate(180deg);
							}

							&[aria-expanded="true"] .eventTripsIcon-fas-chevron-down {
								transition: transform ease 0.3s;
								transform: rotate(0deg);
							}
						}

						.eventTripsIcon-fas-chevron-down {
							margin: 25px 25px 0 0;

							@include media-breakpoint-up(lg) {
								margin: 15px 15px 0 0;
							}
						}

						.card-body {
							max-height: 380px;
							padding: 0 10px 35px 10px;
							font-size: 14px;
							line-height: 18px;

							.content {
								padding: 20px 25px 25px 20px;
								color: $promotionBanner-color;
								background-color: $promotionBanner-content-background-color;
								border-radius: 3px;

								> p {
									margin: 0 0 20px 0;
								}

								ul {
									padding-top: 0;
									padding-bottom: 0;
									margin-bottom: 0;

									li {
										position: relative;
										padding: 0 0 0 10px;
										margin-bottom: 10px;

										&:before {
											position: absolute;
											top: -3px;
											left: -20px;
											width: 20px;
											height: 20px;
											margin: 0;
											font-family: "eventTripsIcon";
											font-size: 10px;
											font-style: normal;
											font-weight: normal;
											font-variant: normal;
											line-height: 20px;
											color: $white;
											text-align: center;
											text-transform: none;
											vertical-align: middle;
											content: "\e938";
											background-color: $promotionBanner-listItemBackground;
											border-radius: 20px;
											-webkit-font-smoothing: antialiased;
											-moz-osx-font-smoothing: grayscale;
										}

										&:last-of-type {
											margin-bottom: 0;
										}
									}
								}
							}

							p.link {
								margin: 20px 0 0 20px;

								a {
									color: $white;
									text-decoration: underline;
								}
							}
						}
					}
				}
			}

			&.hasViaplayPromotionModule {
				.logos {
					padding-bottom: 50px;
				}
			}
		}
	}
}

@include footerDesign();