@import "user-mixins";

$requestTripReadAccess-switch-btn-color: $primary-blue !default;
$requestTripReadAccess-switch-btn-color: $primary-blue !default;
$requestTripReadAccess-submit-btn-color: $white !default;
$requestTripReadAccess-submit-btn-background: $primary-blue !default;
$requestTripReadAccess-submit-btn-hover-background: $tertiary-blue !default;

$passwordReset-login-link-color: $white !default;
$passwordReset-login-link-background: $tertiary-blue !default;

#formsContainer {
	.modal-header {
		position: absolute;
		right: 0;
		display: block;
		border: unset;

		button.close {
			z-index: 1;
		}
	}

	.modal-dialog .modal-content {
		border-radius: 3px;
	}

	&.desktop {
		.modal-header {
			display: none;
		}

		.modal-dialog {
			position: absolute;
			width: 350px;
			margin: 0;

			.modal-content {
				border-radius: 3px 0 3px 3px;
			}
		}
	}

	.icon {
		display: none;

		@include media-breakpoint-up(lg) {
			position: absolute;
			top: -39px;
			right: 0;
			display: block;
			width: 42px;
			height: 39px;
			line-height: 39px;
			text-align: center;
			vertical-align: middle;
			background-color: $white;
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;

			div {
				i {
					font-size: 20px;
					color: $primary-blue;
					vertical-align: middle;
				}

				.badge {
					position: absolute;
					top: 6px;
					right: 4px;
					width: 16px;
					height: 16px;
					padding: 0;
					line-height: 16px;
					background-color: $primary-blue;
					border-radius: 8px;

					i {
						font-size: 10px;
						line-height: 15px;
						color: $white;
					}
				}
			}
		}
	}

	form {
		.row > .col-sm-12 {
			padding: 0;
		}

		.title {
			margin: 0 0 15px 0;

			@include sf_pro_textbold;
			font-size: 20px;
			line-height: 33px;
			color: $primary-blue;
			vertical-align: middle;
		}

		.formContainer {
			@include formBg();
			padding: 0;
			margin: 0;
			background-color: transparent;

			.form-group {
				position: relative;
				margin: 0;

				&:not(:first-child) {
					margin: 15px 0 0 0;
				}
			}

			@include toggleBtn();

			label {
				margin: 0 0 4px 4px;

				@include sf_pro_textbold;
				font-size: 14px;
				line-height: 17px;
				color: $text-blue;
				vertical-align: middle;
			}

			@include inputField(false);

			.submitButton {
				width: 100%;
				height: 44px;
				padding: 0;
				margin: 15px 0;

				@include sf_pro_textsemibold;
				font-size: 14px;
				line-height: 44px;
				color: $white;
				vertical-align: middle;
				background-color: $tertiary-blue;
				border-radius: 10px;
				box-shadow: unset;
			}
		}

		.confirmationEmailResentSuccessMessage {
			padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x) !important;
		}
	}

	.request-trip-read-access-form {
		@import "easy-booking-management-promo";

		.formContainer {
			label {
				font-size: 12px;

				@include sf_pro_textlight;
			}

			.submitButton {
				height: auto;
				padding: 10px 0;
				margin: 30px 0 20px;
				font-size: 16px;
				line-height: var(--bs-btn-line-height);
				color: $requestTripReadAccess-submit-btn-color;
				background-color: $requestTripReadAccess-submit-btn-background;
				box-shadow: none;

				@include sf_pro_textbold;

				&:hover {
					background-color: $requestTripReadAccess-submit-btn-hover-background;
				}
			}
		}
	}
}

.loginFormContainer {
	.formContainer {
		@include legalStatements();

		.requestPasswordResetButton {
			font-size: 13px;
			line-height: 17px;
			color: $tertiary-blue;
			text-align: right;
			vertical-align: middle;
			cursor: pointer;

			@include sf_pro_textregular;
		}

		.register {
			font-size: 13px;
			line-height: 17px;
			color: $text-blue;
			text-align: center;
			vertical-align: middle;

			@include sf_pro_textregular;

			a {
				color: $tertiary-blue;
				text-decoration: underline;
			}
		}

		.g-recaptcha {
			margin: 10px 0 0 0;

			> div {
				margin: 0 auto;
			}
		}

		.resend-confirmation-email {
			padding: 0;
			color: inherit;
			text-decoration: underline;
			background: none;
			border: none;
		}

		.trip-read-access-promo {
			hr {
				margin-bottom: 30px;
			}

			.trip-read-access-info {
				font-size: 14px;
				text-align: center;
			}

			.switch-to-request-trip-read-access-form {
				height: auto;
				padding: 10px;
				color: $requestTripReadAccess-switch-btn-color;
				border: 1px solid $requestTripReadAccess-switch-btn-color;
			}
		}
	}
}

.requestPasswordResetFormContainer {
	.preText, .afterText {
		color: $text-blue;
		vertical-align: middle;

		@include sf_pro_textregular;
	}

	.preText {
		font-size: 16px;
		line-height: 20px;
	}

	.afterText {
		font-size: 13px;
		line-height: 17px;
		text-align: center;

		a {
			color: $tertiary-blue;
			text-decoration: underline;
		}
	}

	.successfulPasswordResetMessageContainer {
		.loginLink {
			width: 100%;
			height: 44px;
			padding: 0;
			margin-top: 15px;
			font-size: 14px;
			line-height: 44px;
			color: $passwordReset-login-link-color;
			vertical-align: middle;
			background-color: $passwordReset-login-link-background;
			border-radius: 10px;
			box-shadow: unset;

			@include sf_pro_textsemibold;
		}
	}

	.successfulPasswordResetMessage {
		margin: 0;
		color: $secondary-green-hover;
		background-color: $secondary-green-light;
		border: 1px solid $primary-blue-light;
	}
}