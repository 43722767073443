@import "../variables/layout/language-selector";

#desktopLanguageSelector, #mobileLanguageSelector {
	.languageSelector {
		width: $langSelector_flagWith;
		cursor: pointer;
	}
}

#desktopLanguageSelector {
	margin: 0 15px 0 0;

	@include media-breakpoint-down(lg) {
		display: none;
	}
}

#mobileLanguageSelector {
	position: absolute;
	top: 13px;
	right: 50px;

	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.languageSelectorPopover {
	max-width: unset;

	.closeBtn {
		display: none;
	}

	a, div {
		display: inline-block;
		color: $primary-blue;

		@include sf_pro_textsemibold;

		img {
			width: $langSelector_flagWith;
		}

		&.active {
			margin: 0 0 0 10px;
			font-weight: normal;
			opacity: .7;
		}
	}
}